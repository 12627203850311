import { css } from '../consts/css';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

function FourZeroFour() {
    return (
        <>
            <Helmet>
                <title>404 Page Not Found - E-Fox Web Solutions</title>
            </Helmet>
            <div
                className={`bg-code min-h-screen flex justify-center items-center ${css.pageTopSpacing} pb-lg xl:pb-0`}
            >
                <div
                    className={`max-w-768 ${css.containerSpacing} mx-auto py-xl`}
                >
                    <div className={'text-center'}>
                        <h1 className={'main-heading'}>404</h1>
                        <span
                            className={'text-white text-base block font-normal'}
                        >
                            <p>
                                The page you were looking for could not be
                                found, please check the web address and try
                                again
                            </p>
                            <div>
                                <Link to={'/'}>
                                    <button className={'btn'}>
                                        Go to Homepage
                                    </button>
                                </Link>
                            </div>
                        </span>
                    </div>
                </div>
            </div>
        </>
    );
}

export default FourZeroFour;
