const containerSpacing = 'px-lg lg:px-xl';
const pageTopSpacing = 'pt-20 lg:pt-28';
export const css = {
    containerSpacing,
    container: `${containerSpacing} max-w-1920 max-auto`,
    pageTopSpacing,
    textPage: `${containerSpacing} flex grow items-center py-md bg-trans max-w-768 min-h-screen ${pageTopSpacing}`,
    textPageInner: 'w-full lg:py-2xl text-left',
    textPageHeading:
        'inline-block w-auto text-3xl lg:block sm:text-4xl main-heading',
    contentSection:
        'px-md py-lg lg:px-lg block border-animation bg-code hover:no-underline text-white h-full',
    caseStudyWrapper: 'bg-black lg:py-2xl text-center',
    caseStudyGrid: 'grid lg:grid-cols-3 text-center pt-md lg:px-md lg:pb-2xl',
    caseStudyItem:
        'lg:px-md sm:h-full flex flex-col pb-xl mb-xl border-b-1 border-b-primary lg:border-b-0 lg:pb-0',
    caseStudyItemLast: 'mb-md px-md sm:h-full flex flex-col lg:mb-xl',
    caseStudyHeadingContainer:
        'max-w-768 mx-auto flex justify-center pt-md mb-xs',
    caseStudyHeading:
        'inline-block border-b border-b-primary pt-md pb-sm lg:mb-2xl',
};
