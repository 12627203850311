import logo from './logo.svg';
import menu from './svg/menu.svg';
import cross from './svg/cross.svg';
import { BrowserRouter, Link, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import WebDevelopment from './pages/WebDevelopment';
import FourZeroFour from './pages/404';
import AppDevelopment from './pages/AppDevelopment';
import UXDesign from './pages/UXDesign';
import Technologies from './pages/Technologies';
import Contact from './pages/Contact';
import { useEffect, useState } from 'react';
import { css } from './consts/css';
import { useLocation } from 'react-router-dom';

function MenuToggler({ setShowMenuXs }) {
    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
        setShowMenuXs(false);
    }, [location.pathname]);
}

function App() {
    const linkCssClassBase =
        'block text-white hover:no-underline hover:text-primary border-b-1 border-b-primary lg:border-0 pb-xs lg:py-0';
    const linkCssClassFirst = `pt-0 ${linkCssClassBase}`;
    const linkCssClass = `pt-md ${linkCssClassBase}`;
    const linkLiClass = 'mb-0';
    const [showMenuXs, setShowMenuXs] = useState(false);

    return (
        <BrowserRouter>
            <MenuToggler setShowMenuXs={setShowMenuXs} />
            <div
                className={`flex flex-col ${
                    process.env.NODE_ENV === 'development'
                        ? 'debug-screens'
                        : ''
                }`}
            >
                <header
                    className={`header lg:flex justify-between items-center ${css.containerSpacing} py-sm lg:py-md absolute lg:fixed top-0 w-full z-40 border-b-1`}
                >
                    <div className={'flex justify-between'}>
                        <Link
                            to={'/'}
                            onClick={() => {
                                setShowMenuXs(false);
                            }}
                        >
                            <img
                                src={logo}
                                className="App-logo w-36 lg:w-48"
                                alt="logo"
                            />
                        </Link>

                        <button
                            type={'button'}
                            onClick={(event) => {
                                event.preventDefault();
                                setShowMenuXs((value) => !value);
                            }}
                        >
                            <img
                                src={menu}
                                className={'w-11 lg:hidden'}
                                alt={'menu'}
                            />
                        </button>
                    </div>

                    <div
                        className={
                            'menu text-white w-full h-0 mb-0 overflow-hidden lg:bg-transparent fixed top-0 left-0 lg:w-auto lg:relative px-0 lg:h-auto'
                        }
                        style={{
                            height: `${showMenuXs ? '100%' : '0%'}`,
                        }}
                    >
                        <div
                            className={`lg:hidden flex pt-lg ${css.containerSpacing}`}
                        >
                            <div className={'grow flex justify-center'}>
                                <Link
                                    to={'/'}
                                    onClick={() => {
                                        setShowMenuXs(false);
                                    }}
                                >
                                    <img
                                        src={logo}
                                        className="App-logo w-36 lg:w-48 ml-2"
                                        alt="logo"
                                    />
                                </Link>
                            </div>

                            <button
                                className={'shrink-0'}
                                type={'button'}
                                onClick={(event) => {
                                    event.preventDefault();
                                    setShowMenuXs((value) => !value);
                                }}
                            >
                                <img
                                    src={cross}
                                    className={'w-8 lg:hidden mr-2'}
                                    alt={'menu'}
                                />
                            </button>
                        </div>
                        <ul
                            className={
                                'p-2xl m-0 lg:p-0 lg:flex lg:items-center  list-none grow justify-end gap-lg overflow-hidden'
                            }
                        >
                            <li className={linkLiClass}>
                                <Link
                                    to={'/web-development'}
                                    className={linkCssClassFirst}
                                >
                                    Web Development
                                </Link>
                            </li>
                            <li className={linkLiClass}>
                                <Link
                                    to={'/app-development'}
                                    className={linkCssClass}
                                >
                                    App Development
                                </Link>
                            </li>
                            <li className={linkLiClass}>
                                <Link
                                    to={'/ux-design'}
                                    className={linkCssClass}
                                >
                                    UX Design
                                </Link>
                            </li>
                            <li className={linkLiClass}>
                                <Link
                                    to={'/technologies'}
                                    className={linkCssClass}
                                >
                                    Technologies
                                </Link>
                            </li>
                            <li className={linkLiClass}>
                                <Link
                                    to={'/contact-us'}
                                    className={linkCssClass}
                                >
                                    Contact
                                </Link>
                            </li>
                        </ul>
                    </div>
                </header>

                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route
                        path="/web-development"
                        element={<WebDevelopment />}
                    />
                    <Route
                        path="/app-development"
                        element={<AppDevelopment />}
                    />
                    <Route path="/ux-design" element={<UXDesign />} />
                    <Route path="/technologies" element={<Technologies />} />
                    <Route path="/contact-us" element={<Contact />} />
                    <Route path="*" element={<FourZeroFour />} />
                </Routes>

                <div
                    className={`bg-dark text-white text-left lg:text-right text-sm right-0 py-2xs ${css.containerSpacing}`}
                >
                    &copy; E-Fox Web Solutions Ltd | 87 Windrush Drive,
                    Hinckley, Leics, LE10 0NY | VAT: 452379969 | Company No:
                    10103975
                </div>
            </div>
        </BrowserRouter>
    );
}

export default App;
