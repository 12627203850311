import avivaLogo from '../svg/aviva.svg';
import avivaWireframing from '../img/aviva-wireframing.png';
import avivaFirstDirect from '../img/aviva-first-direct-white-label.png';
import avivaDevicesAdded from '../img/aviva-devices-added.png';
import appUx1 from '../img/app-ux-1.png';
import appUx2 from '../img/app-ux-2.png';
import appUxBg from '../img/app-ux-bg.png';
import { css } from '../consts/css';
import { Helmet } from 'react-helmet';
function UXDesign() {
    return (
        <>
            <Helmet>
                <title>
                    UX Design - Web & App Design - E-Fox Web Solutions
                </title>
                <meta
                    name="description"
                    content="Immerse yourself in the World of UX Design at E-Fox Web Solutions. Our unparalleled UX design services go beyond aesthetics, strategically focusing on wireframing to lay the foundation for seamless and intuitive user interfaces. We delve into a comprehensive understanding of your business and its unique customer base, ensuring our designs resonate on a meaningful level. Explore our expertise in transforming designs into tangible online products, spanning IoT devices, web interfaces, and cutting-edge mobile applications. Dive into our Case Study, where we overhauled a service portal to deliver an intuitive and efficient experience. From wireframing to a comprehensive user journey, witness how we crafted solutions that resonate with users and elevate digital experiences."
                />
            </Helmet>
            <div
                className={`bg-ux min-h-screen flex justify-center items-center ${css.pageTopSpacing} px-md flex-wrap md:flex-nowrap pb-lg xl:pb-0`}
            >
                <div className={'relative mt-xl md:mt-0 shrink-0'}>
                    <img
                        src={appUx1}
                        className={
                            'left-0 top-0 absolute fade-image w-full z-20'
                        }
                        alt="UX Wireframe"
                    />
                    <img
                        src={appUx2}
                        className={'left-0 top-0 absolute w-full z-10'}
                        alt="UX Design"
                    />
                    <img
                        src={appUxBg}
                        className={'w-32 lg:w-48 mx-auto mb-lg z-0'}
                        alt="Mobile Phone"
                    />
                </div>
                <div
                    className={
                        'flex items-center justify-center mb-xl md:mb-0 shrink'
                    }
                >
                    <div className={'max-w-768 px-xs lg:px-2xl mx-auto'}>
                        <div className={'text-center md:text-left'}>
                            <h1 className={'main-heading'}>UX Design</h1>
                            <span
                                className={
                                    'text-white text-base block font-normal'
                                }
                            >
                                <p>
                                    E-Fox Web Solutions offer unparalleled UX
                                    design services. We specialize in the
                                    intricate art of wireframing, strategically
                                    laying the foundation for seamless and
                                    intuitive user interfaces. Beyond
                                    aesthetics, we delve into a comprehensive
                                    understanding of your business and its
                                    unique customer base, ensuring our designs
                                    resonate on a meaningful level.
                                </p>
                                <p>
                                    Our expertise extends to transforming these
                                    designs into tangible online products,
                                    spanning IoT devices, web interfaces, and
                                    cutting-edge mobile applications.
                                </p>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className={css.caseStudyWrapper}>
                <div className={`py-md ${css.container}`}>
                    <div className={css.contentSection}>
                        <div className={css.caseStudyHeadingContainer}>
                            <div className={'p-xs bg-aviva'}>
                                <img
                                    src={avivaLogo}
                                    className={'w-40'}
                                    alt="Aviva Logo"
                                />
                            </div>
                        </div>
                        <h2 className={css.caseStudyHeading}>
                            Case Study: Aviva Mobile Phone Insurance
                        </h2>
                        <div className={'mx-auto max-w-768'}>
                            <p>
                                {`In collaboration with Dixons Carphone, our
                                company overhauled the mobile phone
                                insurance service portal they provided to Aviva. Using insights from previous
                                analysis, we streamlined processes, allowing
                                users to effortlessly register devices and file
                                claims directly on the portal.`}
                            </p>
                            <p>
                                This user-centric approach resulted in a
                                significant reduction in support center calls,
                                delivering an intuitive and efficient insurance
                                experience.
                            </p>
                        </div>

                        <div className={css.caseStudyGrid}>
                            <div className={`${css.caseStudyItem} justify-end`}>
                                <div
                                    className={
                                        'grow flex items-center justify-center'
                                    }
                                >
                                    <img
                                        src={avivaWireframing}
                                        className={
                                            'w-60 md:w-96 mx-auto mb-lg md:mb-2xl'
                                        }
                                        alt="Aviva Wireframing"
                                    />
                                </div>
                                <p>
                                    We conducted in-depth research on the
                                    existing mobile insurance platform to
                                    identify areas for improvement. Starting
                                    with wireframes and concepts, we worked
                                    closely with the business to refine and
                                    develop these solutions.
                                </p>
                            </div>
                            <div className={`${css.caseStudyItem} justify-end`}>
                                <div className={'grow flex items-center'}>
                                    <img
                                        src={avivaDevicesAdded}
                                        className={
                                            'w-48 xl:w-80 mx-auto mb-lg md:mb-2xl'
                                        }
                                        alt="Aviva Journey"
                                    />
                                </div>
                                <p>
                                    {`We broadened the platform's scope to
                                    encompass insurance cover for multiple device types,
                                    extending its utility beyond mobile phones
                                    and encompassing a comprehensive user
                                    journey covering all gadget types`}
                                </p>
                            </div>
                            <div
                                className={`${css.caseStudyItemLast} justify-end`}
                            >
                                <div>
                                    <img
                                        src={avivaFirstDirect}
                                        className={`w-60 md:w-full ${css.containerSpacing} mx-auto mb-lg md:mb-2xl`}
                                        alt="Aviva Whitelabel"
                                    />
                                </div>
                                <p>
                                    {`To cater for Dixon's Carphone partners, the
                                    insurance platform was developed as a
                                    versatile white-label solution, allowing
                                    seamless branding customization for any
                                    brand to utilize the platform as their
                                    own.`}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default UXDesign;
