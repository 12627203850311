import ContactForm from '../components/ContactForm/ContactForm';
import { css } from '../consts/css';
import { Helmet } from 'react-helmet';

function Contact() {
    return (
        <>
            <Helmet>
                <title>Contact Us - E-Fox Web Solutions, Leicester, UK</title>
                <meta
                    name="description"
                    content="Contact E-Fox Web Solutions Ltd, leave a message for our friendly team"
                />
            </Helmet>
            <div className={'bg-main'}>
                <div className={`${css.textPage} xl:pb-0`}>
                    <div className={`${css.textPageInner} py-xl`}>
                        <h1 className={css.textPageHeading}>Contact Us</h1>
                        <p>
                            Share details about your project, and let us assist
                            you in achieving your goals. Provide your contact
                            information and a brief project description. We look
                            forward to connecting with you to explore how E-Fox
                            Web Solutions can contribute to your success. We
                            endeavor to respond within 2 working days.
                        </p>
                        <ContactForm />
                    </div>
                </div>
            </div>
        </>
    );
}

export default Contact;
