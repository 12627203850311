import mobilesWebDesign from '../img/mobiles-web-design.png';
import beaconWebDesign from '../img/beacon-web-design.png';
import hhWebDesign from '../img/hh-web-design.png';
import mobiles from '../svg/mobiles-logo.svg';
import mobilesMobileFirst from '../img/mobiles-mobile-first.png';
import mobilesDesktopPhone from '../img/mobiles-desktop-phone.png';
import mobilesComparasion from '../img/mobiles-comparasion.png';
import { useEffect, useState } from 'react';
import { css } from '../consts/css';
import { Helmet } from 'react-helmet';

function WebDevelopment() {
    const [imageIndex, setImageIndex] = useState(0);
    const images = [mobilesWebDesign, beaconWebDesign, hhWebDesign];

    useEffect(() => {
        const intervalId = setInterval(() => {
            setImageIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 2500);
        return () => clearInterval(intervalId);
    }, [imageIndex, images.length]);

    return (
        <>
            <Helmet>
                <title>
                    Web Development - React, Javascript, Frontend - E-Fox Web
                    Solutions
                </title>
                <meta
                    name="description"
                    content="Explore Innovative Web Development at E-Fox Web Solutions. Our web development services focus on creating visually appealing and user-friendly websites that adapt seamlessly to various devices, ensuring a consistent experience. With a commitment to accessibility, we prioritize a responsive design that caters to mobiles, tablets, desktops, and more. Discover our case study, where we revitalized an entire online presence by adopting cutting-edge responsive technology. From intuitive navigation and mobile-first design to a streamlined checkout process, witness how we enhance both aesthetics and functionality, providing users with an exceptional online experience. Elevate your web presence with E-Fox Web Solutions."
                />
            </Helmet>
            <div
                className={`bg-iso min-h-screen flex justify-center items-center ${css.pageTopSpacing} pb-lg xl:pb-0`}
            >
                <div
                    className={`max-w-768 ${css.containerSpacing} mx-auto py-xl`}
                >
                    <div className={'text-center lg:text-left'}>
                        <img
                            src={images[imageIndex]}
                            className={'w-80 mx-auto lg:w-md mb-lg'}
                            alt="Responsive Design"
                        />
                        <h1 className={'main-heading'}>Web Development</h1>
                        <span
                            className={'text-white text-base block font-normal'}
                        >
                            <p>
                                {
                                    "Our web development services focus on creating visually appealing and user-friendly websites that adapt to the device in use, whether it's a mobile, tablet, desktop, or anything in between."
                                }
                            </p>
                            <p>
                                E-Fox Web Solutions ensures your web presence is
                                accessible to all, delivering a seamless user
                                experience.
                            </p>
                        </span>
                    </div>
                </div>
            </div>
            <div className={css.caseStudyWrapper}>
                <div className={`py-md ${css.container}`}>
                    <div className={css.contentSection}>
                        <div className={css.caseStudyHeadingContainer}>
                            <img
                                src={mobiles}
                                className={'w-48 lg:w-80'}
                                alt="Mobiles.co.uk Logo"
                            />
                        </div>
                        <h2 className={css.caseStudyHeading}>
                            Case Study: Mobiles.co.uk
                        </h2>
                        <div className={'mx-auto max-w-768'}>
                            <p>
                                Mobiles.co.uk, a prominent online retailer and a
                                member of the Dixons Carphone group, found the
                                need for a fresh website. Leveraging
                                cutting-edge responsive technology, we
                                successfully revitalized the visual identity of
                                Mobiles.co.uk. Our focus was not only on
                                elevating the aesthetics but also on
                                prioritizing enhanced functionality and an
                                improved user experience.
                            </p>
                        </div>

                        <div className={css.caseStudyGrid}>
                            <div className={`${css.caseStudyItem} justify-end`}>
                                <div
                                    className={
                                        'grow flex items-center justify-center'
                                    }
                                >
                                    <div>
                                        <img
                                            src={mobilesComparasion}
                                            className={
                                                'w-48 lg:w-96 mx-auto mb-lg lg:mb-2xl'
                                            }
                                            alt="Mobiles.co.uk Comparasion"
                                        />
                                    </div>
                                </div>
                                <p>
                                    Ensuring customers can easily navigate and
                                    compare various aspects of mobile contracts,
                                    such as tariffs and networks.
                                </p>
                            </div>
                            <div
                                className={`${css.caseStudyItem} justify-center`}
                            >
                                <div
                                    className={
                                        'grow flex items-center justify-center'
                                    }
                                >
                                    <img
                                        src={mobilesMobileFirst}
                                        className={
                                            'w-28 xl:w-48 mx-auto mb-lg lg:mb-2xl'
                                        }
                                        alt="Mobiles.co.uk Mobile Design"
                                    />
                                </div>
                                <p>
                                    {`Adopting a mobile-first design to optimize
                                    performance and enhance visual appeal,
                                    acknowledging the website's primary avenue
                                    for sales.`}
                                </p>
                            </div>
                            <div
                                className={`${css.caseStudyItemLast} justify-end`}
                            >
                                <div
                                    className={
                                        'grow flex items-end justify-center'
                                    }
                                >
                                    <img
                                        src={mobilesDesktopPhone}
                                        className={
                                            'w-64 lg:w-full lg:px-md mx-auto mb-lg lg:mb-2xl'
                                        }
                                        alt="Mobiles.co.uk Checkout"
                                    />
                                </div>
                                <p>
                                    Implementing an intuitively designed
                                    checkout which seamlessly streamlines
                                    purchases cross-platform led to a
                                    substantial boost in conversion rates.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default WebDevelopment;
