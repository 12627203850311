import healthAndHer from '../svg/health-and-her.svg';
import hhSymptomTool from '../img/hh-symptom-tool.png';
import hhSymptomResults from '../img/hh-symptom-results.png';

import hhAppScreen1 from '../img/app/hh-app-screens-1.png';
import hhAppScreen2 from '../img/app/hh-app-screens-2.png';
import hhAppScreen3 from '../img/app/hh-app-screens-3.png';
import hhAppScreen4 from '../img/app/hh-app-screens-4.png';
import hhAppScreen5 from '../img/app/hh-app-screens-5.png';
import hhAppScreen6 from '../img/app/hh-app-screens-6.png';
import hhAppScreen7 from '../img/app/hh-app-screens-7.png';
import hhAppScreen8 from '../img/app/hh-app-screens-8.png';
import hhAppScreen9 from '../img/app/hh-app-screens-9.png';
import hhAppScreen10 from '../img/app/hh-app-screens-10.png';
import hhAppScreen11 from '../img/app/hh-app-screens-11.png';
import hhAppScreen12 from '../img/app/hh-app-screens-12.png';
import hhAppScreen13 from '../img/app/hh-app-screens-13.png';

import { useEffect, useState } from 'react';
import { css } from '../consts/css';
import { Helmet } from 'react-helmet';

function AppDevelopment() {
    const [imageIndex, setImageIndex] = useState(0);
    const images = [
        hhAppScreen1,
        hhAppScreen2,
        hhAppScreen3,
        hhAppScreen4,
        hhAppScreen5,
        hhAppScreen6,
        hhAppScreen7,
        hhAppScreen8,
        hhAppScreen9,
        hhAppScreen10,
        hhAppScreen11,
        hhAppScreen12,
        hhAppScreen13,
    ];

    useEffect(() => {
        // Set up an interval to change the image every 2000 milliseconds (2 seconds)
        const intervalId = setInterval(() => {
            setImageIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 1750);

        // Clear the interval on component unmount to prevent memory leaks
        return () => clearInterval(intervalId);
    }, [imageIndex, images.length]);

    return (
        <>
            <Helmet>
                <title>
                    App Development, Andriod & IOS Apps, SPA - E-Fox Web
                    Solutions
                </title>
                <meta
                    name="description"
                    content="Discover Exceptional App Development Services at E-Fox Web Solutions. From dynamic web and mobile solutions to seamless single-page applications, we leverage React JS and React Native to bring your visions to life. Our versatile solutions cater to both Android and Apple platforms, ensuring broad accessibility. Committed to innovation, our team tailors solutions to meet your business objectives, providing cutting-edge experiences across diverse devices. Explore our expertise in app development and elevate your digital presence with E-Fox Web Solutions."
                />
            </Helmet>
            <div
                className={`bg-iso min-h-screen flex justify-center items-center ${css.pageTopSpacing} px-md flex-wrap md:flex-nowrap pb-lg xl:pb-0`}
            >
                <div className={'mx-auto mb-xs md:mx-0 py-xl xl:py-0 shrink-0'}>
                    <img
                        src={images[imageIndex]}
                        className={'w-36 xl:w-56'}
                        alt="App Development"
                    />
                </div>
                <div className={'flex items-center justify-center'}>
                    <div className={'max-w-768 px-xs lg:px-2xl mx-auto'}>
                        <div className={'text-center md:text-left'}>
                            <h1 className={'main-heading'}>App Development</h1>
                            <span
                                className={
                                    'text-white text-base block font-normal'
                                }
                            >
                                <p>
                                    {
                                        'We excel in dynamic solutions for web and mobile platforms, leveraging React JS and React Native. We provide comprehensive app development services, delivering seamless single-page applications with expert API integration.  '
                                    }
                                </p>
                                <p>
                                    Our solutions are versatile, compatible with
                                    both Android and Apple platforms, ensuring
                                    broad accessibility. Committed to
                                    innovation, our team tailors solutions to
                                    meet your business objectives and captivate
                                    users across diverse devices.
                                </p>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className={css.caseStudyWrapper}>
                <div className={`py-md ${css.container}`}>
                    <div className={css.contentSection}>
                        <div className={css.caseStudyHeadingContainer}>
                            <img
                                src={healthAndHer}
                                className={'w-96'}
                                alt="Health and Her Logo"
                            />
                        </div>
                        <h2 className={css.caseStudyHeading}>
                            Case Study: Health &amp; Her
                        </h2>
                        <div className={'mx-auto max-w-768'}>
                            <p>
                                Health and Her, a pioneering womens health
                                company, collaborated with us to enhance their
                                digital presence and user experience. We
                                successfully redeveloped their website, updated
                                their Menopause App, and enhanced their symptom
                                checker using modern technologies like React.
                                The integration across these platforms resulted
                                in efficient data capture and reuse.
                            </p>
                            <p>
                                {`Our commitment to refining digital solutions
                                aligns seamlessly with Health and Her's mission
                                to revolutionize menopause care, offering women
                                cutting-edge tools and resources for their
                                health journey.`}
                            </p>
                        </div>

                        <div className={css.caseStudyGrid}>
                            <div className={`${css.caseStudyItem} justify-end`}>
                                <div
                                    className={
                                        'grow flex items-center justify-center'
                                    }
                                >
                                    <div>
                                        <img
                                            src={hhAppScreen5}
                                            className={
                                                'w-32 2xl:w-44 mx-auto mb-lg xl:mb-2xl'
                                            }
                                            alt="Health and Her Menopause Mobile App"
                                        />
                                    </div>
                                </div>
                                <p>
                                    Our work on the Menopause App focused on
                                    improving the logging features, providing
                                    users with a seamless experience for
                                    menopause symptom and period tracking.
                                </p>
                            </div>
                            <div className={`${css.caseStudyItem} justify-end`}>
                                <div className={'grow flex items-center'}>
                                    <img
                                        src={hhSymptomTool}
                                        className={
                                            'w-60 xl:w-80 2xl:w-96 mx-auto mb-lg xl:mb-2xl'
                                        }
                                        alt="Health and Her Symptom Checker SPA"
                                    />
                                </div>
                                <p>
                                    {`We enhanced Health and Her's symptom checker
                                    tool for a more intuitive and personalized
                                    experience, accurately identifying and
                                    providing support for womens symptoms and
                                    stages of menopause.`}
                                </p>
                            </div>
                            <div
                                className={`${css.caseStudyItemLast} justify-end`}
                            >
                                <div>
                                    <img
                                        src={hhSymptomResults}
                                        className={
                                            'w-80 xl:w-full px-md mx-auto mb-lg xl:mb-2xl'
                                        }
                                        alt="Health and Her Website"
                                    />
                                </div>
                                <p>
                                    Integrating the symptom checker with the
                                    website enabled dynamic result generation
                                    with links to relevant products &amp;
                                    advice, ensuring comprehensive support for
                                    managing symptoms and access to valuable
                                    resources.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AppDevelopment;
