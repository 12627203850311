import apple from '../svg/apple.svg';
import android from '../svg/android.svg';
import uxDesign from '../svg/ux-design.svg';
import screens from '../img/screens-blank.png';

import aviva from '../svg/aviva.svg';
import beaconInternational from '../svg/beacon-international.svg';
import carphoneWarehouse from '../svg/cpw-logo.svg';
import currys from '../svg/currys-logo.svg';
import firstDirect from '../svg/first-direct.svg';
import healthAndHerApp from '../img/healthandher-app.png';
import mobiles from '../svg/mobiles-logo.svg';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import ContactForm from '../components/ContactForm/ContactForm';
import { css } from '../consts/css';
import { Helmet } from 'react-helmet';

function Home() {
    const [showContactForm, setShowContactForm] = useState(false);
    const logoContainerCssClass =
        'flex items-center justify-center mx-lg mb-lg lg:mx-3xl lg:mb-xl';
    const ctaLinkCssClass =
        'block border-animation no-border-animation-hover p-lg bg-code cursor-pointer hover:no-underline text-white h-full';
    return (
        <>
            <Helmet>
                <title>
                    E-Fox Web Solutions - Web & App Development, Leicester, UK
                </title>
                <meta
                    name="description"
                    content="Elevate Your Digital Presence with E-Fox Web Solutions. Expertise in Web Development, App Development, and UX Design. Transform Ideas into Stunning, User-Friendly Experiences. Explore Responsive Screens, Websites, and App Development Services. Cultivate Exceptional User Experiences with Our Unrivaled UX Design. Unlock the Power of Digital Innovation with E-Fox Web Solutions."
                />
            </Helmet>
            <div className={'bg-main'}>
                <div className={css.textPage}>
                    <div className={css.textPageInner}>
                        {showContactForm ? (
                            <div className={'mb-xl pt-md xl:p-0'}>
                                <h1 className={css.textPageHeading}>
                                    Get In Touch
                                </h1>
                                <ContactForm
                                    setShowContactForm={setShowContactForm}
                                />
                            </div>
                        ) : (
                            <>
                                <h1
                                    className={
                                        'inline-block w-auto text-3xl lg:block sm:text-4xl lg:text-5xl main-heading'
                                    }
                                >
                                    Digital Solutions
                                </h1>
                                <span className={'text-base block font-normal'}>
                                    <p>
                                        From responsive web design to
                                        cutting-edge app development, we
                                        transform your ideas into stunning,
                                        user-friendly experiences.
                                    </p>
                                    <div className={'md:text-right'}>
                                        <button
                                            className={'btn'}
                                            onClick={(event) => {
                                                event.preventDefault();
                                                setShowContactForm(true);
                                                window.scrollTo(0, 0);
                                            }}
                                        >
                                            Get In Touch
                                        </button>
                                    </div>
                                </span>
                            </>
                        )}
                    </div>
                </div>
                <div
                    className={
                        'app-bg border-t border-b border-primary py-2xl flex flex-col justify-center items-center min-h-500 px-md'
                    }
                >
                    <h2 className={'mb-xl'}>{"Brands We've Worked With"}</h2>
                    <div>
                        <div
                            className={
                                'flex justify-center px-md lg:px-2xl flex-wrap'
                            }
                        >
                            <div className={logoContainerCssClass}>
                                <img
                                    src={currys}
                                    className={'w-20 lg:w-28 h-auto'}
                                    alt="Currys"
                                />
                            </div>
                            <div className={logoContainerCssClass}>
                                <img
                                    src={firstDirect}
                                    className={'w-32 lg:w-40 h-auto'}
                                    alt="First Direct"
                                />
                            </div>
                            <div className={logoContainerCssClass}>
                                <div className={'p-xs bg-aviva h-auto'}>
                                    <img
                                        src={aviva}
                                        className={'w-32 lg:w-40 h-auto'}
                                        alt="Aviva"
                                    />
                                </div>
                            </div>
                            <div className={logoContainerCssClass}>
                                <img
                                    src={carphoneWarehouse}
                                    className={'w-40 lg:w-64 h-auto'}
                                    alt="Carphone Warehouse"
                                />
                            </div>
                            <div className={logoContainerCssClass}>
                                <img
                                    src={mobiles}
                                    className={'w-32 lg:w-40 h-auto'}
                                    alt="Mobiles.co.uk"
                                />
                            </div>
                            <div className={logoContainerCssClass}>
                                <div className={'bg-beacon rounded-sm px-sm'}>
                                    <img
                                        src={beaconInternational}
                                        className={'w-20 lg:w-28 h-auto'}
                                        alt="Beacon International"
                                    />
                                </div>
                            </div>
                            <div className={logoContainerCssClass}>
                                <img
                                    src={healthAndHerApp}
                                    className={'w-16 lg:w-20 h-auto'}
                                    alt="Health and Her"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'bg-black py-md xl:py-2xl'}>
                    <h2
                        className={
                            'inline-block border-b border-b-primary xl:pt-md pb-sm'
                        }
                    >
                        Our Services
                    </h2>
                    <div className={'grid xl:grid-cols-3 px-md text-left'}>
                        <div className={'lg:p-md mb-md lg:mb-0'}>
                            <Link
                                to={'/web-development'}
                                className={ctaLinkCssClass}
                            >
                                <div
                                    className={'flex flex-wrap justify-between'}
                                >
                                    <img
                                        src={screens}
                                        className="w-40 fill-white mb-lg"
                                        alt="Responsive Screens"
                                    />
                                    <div
                                        className={
                                            'hidden md:inline-block md:text-right'
                                        }
                                    >
                                        <button className={'btn'}>
                                            View More
                                        </button>
                                    </div>
                                </div>
                                <h3 className={'border-b border-primary pb-xs'}>
                                    Responsive Websites
                                </h3>
                                <p>
                                    Elevate your online presence with a
                                    mobile-friendly website that adapts to all
                                    devices.
                                </p>
                            </Link>
                        </div>

                        <div className={'lg:p-md lg:h-full mb-md lg:mb-0'}>
                            <Link
                                to={'/app-development'}
                                className={ctaLinkCssClass}
                            >
                                <div
                                    className={
                                        'flex flex-wrap justify-between '
                                    }
                                >
                                    <div className={'flex mb-lg'}>
                                        <img
                                            src={apple}
                                            className="w-20 mr-sm"
                                            alt="Apple"
                                        />
                                        <img
                                            src={android}
                                            className="w-20"
                                            alt="Android"
                                        />
                                    </div>
                                    <div
                                        className={
                                            'hidden md:inline-block md:text-right'
                                        }
                                    >
                                        <button className={'btn'}>
                                            View More
                                        </button>
                                    </div>
                                </div>
                                <h3 className={'border-b border-primary pb-xs'}>
                                    App Development
                                </h3>
                                <p>
                                    Turn your mobile app visions into reality
                                    with our professional app development
                                    services, compatible with Apple and Android.
                                </p>
                            </Link>
                        </div>
                        <div className={'lg:p-md lg:h-full'}>
                            <Link to={'/ux-design'} className={ctaLinkCssClass}>
                                <div
                                    className={
                                        'flex flex-wrap justify-between '
                                    }
                                >
                                    <img
                                        src={uxDesign}
                                        className="w-24 mb-lg"
                                        alt="design tablet"
                                    />
                                    <div
                                        className={
                                            'hidden md:inline-block md:text-right'
                                        }
                                    >
                                        <button className={'btn'}>
                                            View More
                                        </button>
                                    </div>
                                </div>
                                <h3 className={'border-b border-primary pb-xs'}>
                                    UX Design
                                </h3>
                                <p>
                                    Cultivate exceptional user experiences that
                                    captivate and retain your audience with our
                                    unrivaled UX design expertise
                                </p>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Home;
